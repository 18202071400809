// NOTE: privacy policy and tos may contain a string $langcode which will be replaced with the currently set language code of the user
export const termsAndConditionsLink =
  "/policies/Nutzungsbedingungen_WhatzLearn_03-25.pdf";
export const privacyPolicyLink =
  "/policies/Datenschutzhinweis_WhatzLearn_03-25.pdf";

export const headerlogo = "/img/logo.png";

export const showDetailsForQuiz = false;
export const showDetailsForSurvey = false;
export const showSeal = true;
export const showCovidView = false;
export const showCovidCertificatesToAdmin = false;
export const showTestCenterView = true;

export const title = "whatzlearn";

export const registrationRequiredDetails = {
  phone: { request: false, mandatory: false },
  address: { request: false, mandatory: false },
  lastname: { request: true, mandatory: true },
  firstname: { request: true, mandatory: true },
  birthday: { request: false, mandatory: false },
  birthyear: { request: false, mandatory: false },
  company: { request: false, mandatory: false },
};

export const qGateFields = [
  { id: "birthdate", type: "date", label: "birthday", mandatory: true },
  { id: "birthplace", type: "string", label: "birthplace", mandatory: true },
  // NOTE: nationalityWithNonEuVisa also adds the additional, optional fields socialSecurity and workPermit (if the user is not from the EU)
  {
    id: "nationality",
    type: "nationalityWithNonEuVisa",
    label: "nationality",
    mandatory: true,
  },
  { id: "address", type: "string", label: "address", mandatory: true },
  { id: "city", type: "string", label: "city", mandatory: true },
  { id: "zipCode", type: "string", label: "zipCode", mandatory: true },
  { id: "country", type: "country", label: "country", mandatory: true },
  {
    id: "car",
    type: "switchString",
    label: "car",
    mandatory: false,
    settings: { subLabel: "carPlate", subId: "carPlate" },
  },
];

export const emailAsUsername = true;

export const supportedLanguages = [
  "de",
  "en",
  "bg",
  "cs",
  "el",
  "hr",
  "hu",
  "it",
  "lv",
  "pl",
  "pt",
  "ru",
  "ro",
  "es",
  "tr",
];
